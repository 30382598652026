import axios from "axios";
import React, { useRef, useState } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import { toast } from "react-toastify";

import { ImSpinner } from "react-icons/im";

import { useSelector } from "react-redux";
import { ChooseFileContainer } from "../../ClientsList/SubUserForm/styles";
import "./style.css";

const base_url = "https://developers.honely.com";

const CustomPDFCover = (props) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const [previewUrl, setPreviewUrl] = useState(null); // Store the image preview URL
  const [pdfReportCoverData, setPdfReportCoverData] = useState(null);

  const [file, setFile] = useState(null);

  const cognitoUser = useSelector((state) => state.cognitoUser);
  const jwtToken = cognitoUser?.jwtToken;

  const resetReportCover = async () => {
    setIsReset(true);

    try {
      let res = await axios.delete(
        `${base_url}/partner/users/pdf/reset-front-image?client_id=${props?.clientState?.result?.user_id}`,
        {
          withCredentials: false,
          headers: {
            Authorization: "Bearer " + jwtToken,
          },
        }
      );
      console.log(res?.data);
      if (res?.data?.data) {
        toast.success(
          `The report cover image has been successfully reset to default. If you don't see the update, please refresh the screen.`
        );
        props?.setShowCustomCoverModal(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Something went wrong while reseting report cover image, please try again later!"
      );
    }
    setIsReset(false);
  };

  // validate and impor the watchlist
  const submitNewCover = async () => {
    if (!file) {
      toast.error("Please select a new cover image for your PDF report.");
      return;
    }

    setIsSubmit(true);
    uploadCover();
  };

  // create new smart contract when submit the its name
  const uploadCover = async () => {
    let body = {
      client_id: props?.clientState?.result?.user_id,
      front_image: pdfReportCoverData,
    };

    console.log("body", body);

    try {
      let res = await axios.post(
        `${base_url}/partner/users/pdf/upload-front-image`,
        body,
        {
          withCredentials: false,
          headers: {
            Authorization: "Bearer " + jwtToken,
          },
        }
      );
      console.log(res?.data);
      if (res?.data?.data) {
        toast.success(
          `The report cover image has been successfully uploaded. If you don't see the update, please refresh the screen.`
        );
        props?.setShowCustomCoverModal(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Something went wrong while uploading new report cover image, please try again later!"
      );
    }
    setIsSubmit(false);
  };

  // get the file content
  const handleFileChange = (e) => {
    console.log("file uplaod gain");
    e.preventDefault();
    const fileInput = e.target; // Save a reference to the input element

    const file = fileInput.files[0];

    if (!file) return;

    // Check file size (must be less than 2MB)
    const maxSizeInMB = 2;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // 2MB in bytes
    if (file.size > maxSizeInBytes) {
      toast.error(
        `File size exceeds ${maxSizeInMB} MB. Please upload a smaller file.`
      );
      return;
    }

    const fileReader = new FileReader();

    console.log("file", file);

    if (file) {
      fileReader.onload = function (event) {
        const image = new Image();
        image.src = event.target.result;

        image.onload = function () {
          /* validate image aspect ratio logic   */
          // const width = image.width;
          // const height = image.height;
          // const aspectRatio = width / height;

          // // Check if the aspect ratio is approximately 1.29:1
          // const expectedAspectRatio = 1.29;
          // const tolerance = 0.05; // Allow slight tolerance in aspect ratio

          // if (Math.abs(aspectRatio - expectedAspectRatio) > tolerance) {
          //   toast.error(
          //     `Aspect ratio is incorrect. Expected a ratio close to 1.29:1 (Letter size format).`
          //   );
          //   setFile(null);
          //   return;
          // }

          // If checks pass, set the preview URL
          setPreviewUrl(event.target.result);
          setPdfReportCoverData(
            event.target.result.replace(/^data:image\/[a-z]+;base64,/, "")
          );
          setFile(file);
        };
        // const text = event.target.result;
        // setPreviewUrl(event?.target?.result);
        // console.log(content);
      };

      fileInput.value = "";
      fileReader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="h-full">
        <div className="px-2 flex flex-col gap-2 h-full justify-between">
          <div className="w-full flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <span className=" text-black text-2xl">
                {" "}
                Customize Report Cover{" "}
              </span>
            </div>

            <div className="flex flex-col gap-2">
              <span className="text-sm">Choose a Cover Image:</span>
              <div className="flex flex-col items-center justify-center w-full ">
                <ChooseFileContainer className="w-full py-3">
                  <label
                    htmlFor="dropzone-file"
                    className="flex gap-2 items-center sm:flex-col"
                  >
                    <span className="font-medium text-sm bg-gray-200 p-2 px-4 rounded-full cursor-pointer">
                      Browse for File
                    </span>

                    {!file ? (
                      <span className="text-sm">No File Uploaded</span>
                    ) : (
                      <span className="text-sm">{file?.name}</span>
                    )}

                    <input
                      id="dropzone-file"
                      type="file"
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={handleFileChange}
                      className="hidden"
                      disabled={isSubmit ? true : false}
                    />
                  </label>
                </ChooseFileContainer>
              </div>
            </div>

            {previewUrl && (
              <div className="flex flex-col gap-2">
                <span className="text-sm">Cover Image Preview:</span>
                <div className="relative ">
                  {/* Image */}
                  <img
                    src={previewUrl}
                    alt="Preview"
                    className="object-contain border border-gray-300 w-full h-[500px]"
                  />

                  <div className="absolute flex justify-center items-center top-[15%] left-0 right-0">
                    <span className="text-white text-4xl font-bold sm:text-lg">
                      {"Property Report"}
                    </span>
                  </div>

                  <img
                    src={
                      "https://honely-files-public.s3.amazonaws.com/report/logo_honely_report_default.png"
                    }
                    alt="Realtor Logo"
                    className="absolute m-auto left-0 right-0 bottom-10 w-[100px] sm:w-[50px]"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="py-2 pt-3 text-center flex items-center gap-2 sm:flex-col">
            <button
              className={`rounded-md w-full bg-[#FF9900] hover:bg-[#d88200] text-white font-semibold py-2.5 flex items-center justify-center`}
              onClick={() => (isSubmit ? {} : submitNewCover())}
            >
              {isSubmit && (
                <svg className="animate-spin mr-3 h-5 w-5">
                  <ImSpinner className="text-[1.3rem] text-white " />
                </svg>
              )}
              Upload new cover
            </button>

            <button
              className={`rounded-md w-full bg-[#FF9900] hover:bg-[#d88200] text-white font-semibold py-2.5 flex items-center justify-center`}
              onClick={() => (isReset ? {} : resetReportCover())}
            >
              {isReset && (
                <svg className="animate-spin mr-3 h-5 w-5">
                  <ImSpinner className="text-[1.3rem] text-white " />
                </svg>
              )}
              Reset default cover
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomPDFCover;
