import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Input, Button } from '../Shared'
import BisError from '@meronex/icons/bi/BisError'
import { cognitoCompleteNewPassword } from '../../store/reducers/cognitoUser'
import { useToast, ToastType } from '../../contexts/ToastContext'

import {
  FormController,
  NewPasswordContainer,
  ValidationError
} from './styles'

export const NewPassword = (props) => {
  const [submitState, setSubmitState] = useState({ loading: false, error: null })

  const [, { showToast }] = useToast()
  const dispatch = useDispatch()
  const { register, handleSubmit, formState: { errors }, watch  } = useForm()
  const newPassword = watch('newpassword', '')

  const onSubmit = async (formState) => {
    try {
      setSubmitState({ ...submitState, loading: true })
      await dispatch(cognitoCompleteNewPassword(formState.newpassword))
      setSubmitState({ loading: false, error: null })
    } catch (error) {
      setSubmitState({ loading: false, error: [error.message] })
      if (error?.message === 'Invalid session for the user, session is expired.') {
        showToast(ToastType.Info, 'Please login again.')
        props.onClose()
      }
    }
  }

  const checkPasswordValidation = (value) => {
    if (!(/[\d]/i).test(value)) {
      return 'Password should contain at least 1 number'
    } else if (!(/[\!@#$%^&\*\(\)\_\+-]/i).test(value)) {
      return 'Password should contain at least 1 special charactor'
    } else if (!/(?=.*[A-Z])/.test(value)) {
      return 'Password should contain at least 1 uppercase character'
    } else if (!/(?=.*[a-z])/.test(value)) {
      return 'Password should contain at least 1 lowercase character'
    } else {
      return true
    }
  }

  return (
    <NewPasswordContainer onSubmit={handleSubmit(onSubmit)}>
      <FormController>
        <label>New password</label>
        <Input
          type='password'
          {
            ...register('newpassword',
            {
              required: { value: true, message: 'The field new password is required' },
              minLength: { value: 8, message: 'At least 8 characters in length' },
              validate: checkPasswordValidation
            })
          }
        />
        {errors.newpassword?.message && <ValidationError><BisError /> {errors.newpassword?.message}</ValidationError>}
      </FormController>
      <FormController>
        <label>Confirm new Password</label>
        <Input
          type='password'
          {
            ...register('confirm_password',
            {
              required: { value: true, message: 'The field confirm password is required' },
              validate: value => value === newPassword || 'The passwords do not match'
            })
          }
        />
        {errors.confirm_password?.message && <ValidationError><BisError /> {errors.confirm_password?.message}</ValidationError>}
      </FormController>
      <Button
        color='primary'
        type='submit'
      >
        {submitState.loading ? 'Loading...' : 'Submit'}
      </Button>
    </NewPasswordContainer>
  )
}
