import React, { useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { Header } from "./components/Header";
import { Alert } from "./components/Shared";
import { doGet } from "./services/http-client";
import { setPartner } from "./store/reducers/partner";

// pages
import { AccountManagement } from "./pages/AccountManagement";
import { Billing } from "./pages/Billing";
import { ClientDetail } from "./pages/ClientDetail";
import { ClientsList } from "./pages/ClientsList";
import { CustomizeWidget } from "./pages/CustomizeWidget";
import { Login } from "./pages/Login";
import { PageNotFound } from "./pages/PageNotFound";

export const App = () => {
  const dispatch = useDispatch();
  const cognitoUser = useSelector((state) => state.cognitoUser);
  const auth = cognitoUser?.isLoggedIn;
  const [alertState, setAlertState] = useState({ open: false, content: [] });

  const getPartner = async () => {
    try {
      const response = await doGet("partner");
      if (response?.error) {
        throw response.error;
      }
      dispatch(setPartner(response?.data));
    } catch (error) {
      setAlertState({ open: true, content: error.message });
    }
  };
  useEffect(() => {
    if (auth) {
      getPartner();
    }
  }, [auth]);

  return (
    <>
      {auth && <Header />}
      <Routes>
        <Route
          path="/"
          element={auth ? <Navigate to="/clients" /> : <Navigate to="/login" />}
        />
        <Route
          path="/login"
          element={auth ? <Navigate to="/clients" /> : <Login />}
        ></Route>
        <Route
          path="/clients"
          element={auth ? <ClientsList /> : <Navigate to="/login" />}
        ></Route>
        <Route
          path="/clients/:apiKey"
          element={auth ? <ClientDetail /> : <Navigate to="/login" />}
        ></Route>
        <Route
          path="/clients/:apiKey/custom-widget"
          element={auth ? <CustomizeWidget /> : <Navigate to="/login" />}
        ></Route>
        <Route
          path="/billing"
          element={auth ? <Billing /> : <Navigate to="/login" />}
        ></Route>
        <Route
          path="/account-management"
          element={auth ? <AccountManagement /> : <Navigate to="/login" />}
        ></Route>
        <Route path="*" element={<PageNotFound />}></Route>
      </Routes>

      <Alert
        title="Error"
        width="700px"
        content={alertState.content}
        acceptText={"Accept"}
        open={alertState.open}
        onClose={() => setAlertState({ open: false, content: [] })}
        onAccept={() => setAlertState({ open: false, content: [] })}
        closeOnBackdrop={false}
      />
    </>
  );
};
