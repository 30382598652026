import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

const ConfirmationMsg = (props) => {
  console.log("props?.failedImportUsers", props?.failedImportUsers);

  return (
    <div className="flex flex-col h-full">
      <AiFillCheckCircle className="text-green-500 w-24 h-24 mx-auto my-6" />

      <div className="text-center h-full flex flex-col flex-1">
        <h3 className="text-2xl font-semibold text-center text-green-500">
          {props?.title}
        </h3>
        <p className="text-black  my-2 w-[90%] md:w-full mx-auto">
          {props?.text}
        </p>
        {props?.subText && <p className="text-black"> {props?.subText} </p>}

        {props?.failedImportUsers && props?.failedImportUsers?.length > 0 && (
          <div className="w-full px-8 md:px-0 ">
            <p className="text-left text-red-300 text-base mb-2">
              Skipped Users
            </p>

            <div className="overflow-auto max-h-[250px] md:max-h-none">
              <table className="w-full text-sm text-left ">
                {/* css border-b border-gray-400  */}
                <thead className="sticky top-0 bg-[#f8d4d6] text-xs text-[#7E7D8B] whitespace-nowrap z-[1] ">
                  <tr>
                    <th scope="col" className={`px-3 py-2 font-bold`}>
                      <div className="flex gap-1">{"Email"}</div>
                    </th>
                    <th scope="col" className={`px-3 py-2 font-bold`}>
                      <div className="flex gap-1">{"Reason"}</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-300">
                  {props?.failedImportUsers?.map((user, index) => {
                    return (
                      <tr className={`bg-[#FFEAEB] rounded-md`} key={index}>
                        <td className="px-3 py-2 whitespace-nowrap text-xs">
                          {user?.email ?? "--"}
                        </td>

                        <td className="px-3 py-2 whitespace-nowrap text-xs">
                          {user?.reason ?? "--"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <div className="flex-1 py-2 pt-4 text-center mt-auto ">
          <button
            onClick={() => props?.close()}
            className="px-14 max-w-max md:w-full bg-[#FF9900] hover:bg-[#c99341] text-white font-semibold py-1.5 rounded-md"
          >
            {props?.backButton?.name}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationMsg;
