import React from 'react'
import { Billing as BillingController } from '../../components/Billing'

export const Billing = (props) => {
  return (
    <>
      <BillingController {...props} />
    </>
  )
}
