import React, { useEffect, useState } from "react";

const StatusToggle = ({ clientState, updateClient, actionState }) => {
  const [isChecked, setIsChecked] = useState(false);

  // Map the status to the toggle state
  useEffect(() => {
    if (clientState?.result?.status === "ACTIVE") {
      setIsChecked(true);
    } else if (clientState?.result?.status === "INACTIVE") {
      setIsChecked(false);
    }
  }, [clientState]);

  // Handle the toggle change
  const handleToggleChange = async () => {
    const newStatus = isChecked ? "INACTIVE" : "ACTIVE";
    setIsChecked(!isChecked);

    try {
      // Call the API to update the status
      await updateClient({
        status:
          clientState.result?.status !== "INACTIVE" ? "INACTIVE" : "ACTIVE",
      });

      // console.log("Status updated to:", newStatus);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="sr-only peer"
        checked={isChecked}
        onChange={handleToggleChange}
        disabled={actionState.loading}
      />
      <div className="relative w-11 h-6 bg-gray-200 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#FF9900]"></div>
      <span className="ms-3 text-base font-medium text-gray-900 ">
        {isChecked ? "Active" : "Inactive"}
      </span>
    </label>
  );
};

export default StatusToggle;
