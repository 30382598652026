import axios from "axios";
import React, { useState } from "react";
import { IoCloudUploadOutline } from "react-icons/io5";
import { toast } from "react-toastify";

import { ImSpinner } from "react-icons/im";
import SampleCSV from "../../../assets/files/user-info.csv";
import CrossImg from "../../../assets/images/circle-cross.svg";

import { useSelector } from "react-redux";
import "./style.css";

const base_url = "https://developers.honely.com";

const ImportUserForm = (props) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [csvContent, setCsvContent] = useState([]);
  const [file, setFile] = useState(null);

  const [validatedCSVHeader, setValidatedCSVHeader] = useState(null);

  const cognitoUser = useSelector((state) => state.cognitoUser);
  const jwtToken = cognitoUser?.jwtToken;

  const headers = ["Full Name", "Email", "Company Name", "Company URL"];

  // validate and impor the watchlist
  const submit = async () => {
    if (csvContent?.length === 0) {
      toast.error("Please upload your CSV file.");
      return;
    }

    setIsSubmit(true);
    importCSV();
  };

  // create new smart contract when submit the its name
  const importCSV = async () => {
    let body = {
      users: csvContent,
    };

    console.log("body", body);

    try {
      let res = await axios.post(
        `${base_url}/partner/users/create-user/bulk`,
        body,
        {
          withCredentials: false,
          headers: {
            Authorization: "Bearer " + jwtToken,
          },
        }
      );
      console.log(res?.data);
      if (res?.data?.data) {
        console.log(
          "res?.data?.data?.failed_users",
          res?.data?.data?.failed_users
        );
        if (
          res?.data?.data?.failed_users &&
          res?.data?.data?.failed_users?.length > 0
        ) {
          props?.setFailedImportUsers(res?.data?.data?.failed_users);

          toast.warning(
            `The CSV file is successfully imported, but only a portion of the users were created. Please check the details.`
          );
        } else {
          toast.success(`Your CSV file have been successfully imported`);
        }
        setCsvContent([]);

        props?.onImportUserSuccess();
      }
    } catch (error) {
      console.log(error);
      toast.error(
        "Something went wrong while importing partner users, please try again later!"
      );
    }
    setIsSubmit(false);
  };

  // get the csv file content
  const handleFileChange = (e) => {
    console.log("file uplaod gain");
    e.preventDefault();
    const fileInput = e.target; // Save a reference to the input element

    const file = fileInput.files[0];
    setFile(file);

    const fileReader = new FileReader();

    console.log("file", file);

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        const content = csvFileToArray(text);
        console.log(content);
        setCsvContent(content);
      };

      fileInput.value = "";
      fileReader.readAsText(file);
    }
  };

  // logic to read the csv and store into array based on the header
  const csvFileToArray = (string) => {
    // get the the csv headers
    const csvHeader = string
      ?.slice(0, string.indexOf("\n"))
      ?.split(",")
      .map((header) => header.trim());

    let validatedHeader = {};

    const areHeadersPresent = headers?.filter((key) => {
      const lowercaseKey = key?.toLowerCase();

      const isKeyMissing =
        !csvHeader ||
        !csvHeader?.some((header) => header?.toLowerCase() === lowercaseKey);

      if (isKeyMissing) {
        validatedHeader[key] = !isKeyMissing;
      }
      return isKeyMissing;
    });

    setValidatedCSVHeader(validatedHeader);

    // if there is error
    if (!csvHeader || areHeadersPresent?.length > 0) {
      toast.error(
        "Please make sure all required columns are present and the email address is unique"
      );
      setFile(null);
      return null;
    }

    //todo- new validation here once the email api is ready to validate if the each email is new email not existed one
    // ....

    const csvRows = string?.slice(string.indexOf("\n") + 1)?.split("\n");

    const nameMapper = {
      "full name": "full-name",
      email: "email",
      "phone number": "phone-number",
      "company name": "company-name",
      "company url": "company-url",
      // "company logo url": "company-logo-url",
    };

    const array = csvRows
      ?.map((i) => {
        const values = i?.split(",");

        // Skip rows with an empty
        if (!values[0]?.trim()) {
          return null;
        }

        const obj = csvHeader?.reduce((object, header, index) => {
          const rawValue = values[index]?.trim();
          let parsedValue = rawValue;

          // in case there is "" for the value from the csv file, delete them
          if (parsedValue?.startsWith('"') && parsedValue?.endsWith('"')) {
            parsedValue = parsedValue.slice(1, -1);
          }

          // console.log("header", header);
          // console.log(" header?.toLowerCase()", header?.toLowerCase());
          // console.log(nameMapper[header?.toLowerCase()]);
          object[nameMapper[header?.toLowerCase()]] = parsedValue;
          return object;
        }, {});

        // Exclude objects where all values are empty strings
        if (Object?.values(obj).some((value) => value !== "")) {
          return obj;
        }

        return null;
      })
      ?.filter((obj) => obj !== null && Object?.keys(obj)?.length > 0); // Filter out null and empty objects

    return array;
  };

  return (
    <>
      <div className="h-full">
        <div className="px-2 flex flex-col gap-2 h-full justify-between">
          <div className="w-full flex flex-col gap-4">
            <div className="flex justify-between items-center 1 px-2 pt-2">
              <h2 className=" text-black text-2xl">Import CSV </h2>
            </div>

            <div className="flex flex-col items-center justify-center w-full ">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full border-2 border-dashed rounded-lg cursor-pointer  hover:bg-bray-800  border-gray-400  hover:bg-gray-200"
              >
                {file?.name ? (
                  <p className="mb-2 p-4 text-sm text-gray-500 truncate max-w-[400px] md:max-w-[250px] flex flex-col gap-1.5">
                    {file.name && (
                      <span>
                        {" "}
                        <span className="font-semibold">Name:</span> {file.name}
                      </span>
                    )}

                    {file.size > 0 && (
                      <span>
                        <span className="font-semibold"> Size:</span>{" "}
                        {Math.round(file.size / 1024) !== 0
                          ? Math.round(file.size / 1024)
                          : file.size}{" "}
                        {Math.round(file.size / 1024) !== 0 ? "KB" : "B"}
                      </span>
                    )}

                    {file.lastModified && (
                      <span>
                        <span className="font-semibold"> Last Modified:</span>{" "}
                        {new Date(file?.lastModified).toLocaleString()}
                      </span>
                    )}
                  </p>
                ) : (
                  <div className="flex flex-col gap-1 items-center justify-center py-4 text-[#a3a2a2]">
                    <IoCloudUploadOutline className="text-[2.5rem]" />
                    <p className="text-base text-center mb-0">
                      <span className="font-medium text-lg xsm:text-sm">
                        Click to import a CSV file
                      </span>
                    </p>
                  </div>
                )}

                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  onChange={handleFileChange}
                  accept=".csv"
                  disabled={isSubmit ? true : false}
                />
              </label>
            </div>

            <div className="flex flex-col gap-2">
              <p className="text-sm font-medium mb-0">File requirements</p>
              <div className="flex flex-col gap-2">
                {/* table format for the table preview */}
                <div className="flex flex-col gap-2">
                  <div className="overflow-auto w-full">
                    <table className="w-full text-sm text-left ">
                      <thead className="sticky top-0 bg-[#EBF1FB] text-xs text-[#7E7D8B] whitespace-nowrap z-[1]">
                        <tr>
                          {headers?.map((name, index) => {
                            return (
                              <th
                                scope="col"
                                className={`px-3 py-2 font-bold`}
                                key={index}
                              >
                                <div className="flex gap-1">{name}</div>
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr className={`bg-[#F6F8FC] rounded-md`}>
                          <td className="px-3 py-2 whitespace-nowrap text-xs">
                            Jone Doe
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap text-xs">
                            johndoe@exmaple.com
                          </td>
                          {/* <td className="px-3 py-2 whitespace-nowrap text-xs">
                            9173821111
                          </td> */}
                          <td className="px-3 py-2 whitespace-nowrap text-xs">
                            XYZ
                          </td>
                          <td className="px-3 py-2 whitespace-nowrap text-xs">
                            https://xyz.com
                          </td>
                          {/* <td className="px-3 py-2 whitespace-nowrap text-xs">
                            https://via.placeholder.com/150
                          </td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {validatedCSVHeader &&
              Object.keys(validatedCSVHeader)?.length > 0 && (
                <div className="flex flex-col gap-3 px-3 py-3 bg-red-100 rounded-md ">
                  <p className="text-sm font-medium mb-0">
                    Missing required columns
                  </p>

                  <div className="flex gap-3 flex-wrap">
                    {Object?.keys(validatedCSVHeader)?.map((key, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-1.5 whitespace-nowrap min-w-[160px]"
                      >
                        {/* {validatedCSVHeader[key] ? (
                    <img src={CheckboxImg} alt="" className="w-4" />
                  ) : (
                    <img src={CrossImg} alt="" className="w-4" />
                  )} */}
                        <img src={CrossImg} alt="" className="w-4" />

                        <span className="text-sm">{key}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

            <div className="text-[#888] text-sm">
              <span>
                Click{" "}
                <a
                  href={SampleCSV}
                  className="text-primary underline cursor-pointer"
                  download="User Sample"
                  target="_blank"
                  rel="noreferrer"
                >
                  here
                </a>{" "}
                to download a user sample file
              </span>
            </div>
          </div>

          <div className="pb-2 text-center">
            <button
              className={`rounded-md w-full bg-[#FF9900] hover:bg-[#d88200] text-white font-semibold py-2.5 flex items-center justify-center`}
              onClick={() => (isSubmit ? {} : submit())}
            >
              {isSubmit && (
                <svg className="animate-spin mr-3 h-5 w-5">
                  <ImSpinner className="text-[1.3rem] text-white " />
                </svg>
              )}
              Import
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportUserForm;
